import Pristine from 'pristinejs';

let defaultConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'is-invalid',
    successClass: 'is-valid',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-alert-600 font-black text-xs mt-2 mb-4'
};

// Pristine.addMessages('nl', {
//     required: "Dit veld is verplicht",
//     email: "Vul een geldig E-mailadres in",
//     tel: "Je telefoonnummer is verplicht",
//     equals: "De waarden komen niet overeen",
//     default: "Dit veld is verplicht"
// })

// Pristine.setLocale('nl')

window.addEventListener('load', (e) => {
    const pristineDefaultForms = document.querySelectorAll('[data-pristine="default"]');
    for (let i = 0; i < pristineDefaultForms.length; ++i) {
        let form = pristineDefaultForms[i];
        let pristine = new Pristine(form, defaultConfig, true);
        form.addEventListener('submit', function (e) {
            e.preventDefault();
            // check if the form is valid
            let valid = pristine.validate();
            if (valid) {
                form.submit();
            }
        });
    }
}, {passive: true});