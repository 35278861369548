// core version + navigation, pagination modules:
import Swiper, {Navigation, Pagination, Mousewheel, Controller, Thumbs, FreeMode, Autoplay, EffectCreative, Keyboard} from 'swiper';

function debounce(func, wait, immediate) {
    var timeout
    return function executedFunction() {
        var context = this
        var args = arguments
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

window.swiperMobileOnly = {}
const swiperMobileOptions = {
    modules: [Navigation, Pagination, Mousewheel, Autoplay],
    autoplay: {
        delay: 3000,
    },
    mousewheel: {
        forceToAxis: true,
    },
}

function swiperMobileOnly() {
    const mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
    const tablet = window.matchMedia('(min-width: 768px)');

    function switchStateSwiper(initOrDestroy) {
        for (const [swiperId, swiperInstance] of Object.entries(window.swiperMobileOnly)) {
            if (initOrDestroy == 'destroy') {
                const swiperDomElement = swiperInstance.$el[0]
                swiperDomElement.classList.remove('swiper')
                swiperDomElement.firstElementChild.classList.remove('swiper-wrapper')
                swiperInstance.destroy()
            }
            if (initOrDestroy == 'init') {
                if (swiperInstance.destroyed == true) {
                    const reinitSwiperElement = document.getElementById(swiperId)
                    reinitSwiperElement.classList.add('swiper')
                    reinitSwiperElement.firstElementChild.classList.add('swiper-wrapper')
                    window.swiperMobileOnly[swiperId] = new Swiper(reinitSwiperElement, swiperMobileOptions)
                }
            }
        }
    }

    if (mobile.matches) {
        switchStateSwiper('init')
    } else if (tablet.matches) {
        switchStateSwiper('destroy')
    }
}

if (document.querySelectorAll('.js-vacature-slider').length >= 1) {
    window.addEventListener('resize', debounce(swiperMobileOnly, 300, false), {passive: true})
}

document.querySelectorAll('.js-vacature-slider').forEach(element => {
    window.swiperMobileOnly[element.id] = new Swiper(element, swiperMobileOptions)
    swiperMobileOnly() // ? onload
})

document.querySelectorAll('.js-obaia-home-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Mousewheel,EffectCreative,Keyboard],
        loop: false,
        // freeMode: true,
        grabCursor: true,
        // slidesPerView: "auto",
        // effect: 'slide',
        effect: 'creative',
        keyboard: true,
        mousewheel: {
            forceToAxis: true,
        },
        breakpoints: {
            0: {
                centeredSlides: true,
                spaceBetween: 24,
                slidesPerView: "auto",
                effect: 'creative',
                creativeEffect:{
                    perspective: true,
                    progressMultiplier: 1.2,
                    limitProgress: 2,
                    prev: {
                        translate: ["-120%", 0, -400],
                        rotate: [0,0,0],
                        opacity: 1,
                        scale: 1.1,
                        shadow: false,
                        // origin: 'string',
                    },
                    next: {
                        // Array with translate X, Y and Z values
                        translate: ["120%", 0, -400],
                        rotate: [0,0,0],
                        opacity: 1,
                        scale: 1.1,
                        shadow: false,
                        // origin: 'string',
                    }
                }
            },
            1408: {
                slidesPerView: 4,
                spaceBetween: 0,
                effect: 'slide',
            }
        },
        on: {
            init: function (swiper) {
                const newCurrent = swiper.slides[swiper.activeIndex].getAttribute('data-swiper-current')
                setTimeout(() => {
                    // we need a little delay for this to take effect
                    element.dispatchEvent(new CustomEvent('swiper-change', {
                        detail: {current: newCurrent},
                        bubbles: true
                    }));
                }, 200)
            },
            slideChangeTransitionEnd: function (swiper) {
                // Get the active slide's data attribute
                const newCurrent = swiper.slides[swiper.activeIndex].getAttribute('data-swiper-current')
                element.dispatchEvent(new CustomEvent('swiper-change', {
                    detail: {current: newCurrent},
                    bubbles: true
                }));
            },
        },
    });
});

document.querySelectorAll('.js-obaia-verhalen-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel],
        loop: true,
        centeredSlides: true,
        slidesPerView: "auto",
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            prevEl: element.closest('section').querySelector('.js-obaia-verhalen-slider-prev'),
            nextEl: element.closest('section').querySelector('.js-obaia-verhalen-slider-next')
        },
    });
});

document.querySelectorAll('.js-obaia-weetjes-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel],
        loop: false,
        centeredSlides: true,
        slidesPerView: 1,
        autoHeight: true,
        mousewheel: {
            forceToAxis: true,
        },
        pagination: {
            enabled: true,
            el: element.parentElement.parentElement.querySelector('.js-swiper-weetjes-pagination'),
            clickable: true
        }
        // navigation: {
        //     prevEl: element.closest('section').querySelector('.js-obaia-verhalen-slider-prev'),
        //     nextEl: element.closest('section').querySelector('.js-obaia-verhalen-slider-next')
        // },
    });
});

document.querySelectorAll('.js-obaia-video-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel],
        // loop: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        autoHeight: true,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            prevEl: element.closest('section').querySelector('.js-obaia-video-slider-prev'),
            nextEl: element.closest('section').querySelector('.js-obaia-video-slider-next')
        },
    });
});

document.querySelectorAll('.js-obaia-news-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel],
        // loop: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        autoHeight: true,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            prevEl: element.closest('section').querySelector('.js-obaia-news-slider-prev'),
            nextEl: element.closest('section').querySelector('.js-obaia-news-slider-next')
        },
    });
});

document.querySelectorAll('.js-obaia-history-slider-images').forEach(element => {
    const parentElement = element.closest('section')
    const textElement = parentElement.querySelector('.js-obaia-history-slider-text')
    const thumbsElement = parentElement.querySelector('.js-obaia-history-slider-thumbs')

    const swiperText = new Swiper(textElement, {
        modules: [Navigation, Pagination, Mousewheel, Controller],
        slidesPerView: 1,
        // speed: 1000,
        mousewheel: {
            forceToAxis: true,
        },
        // navigation: {
        //     prevEl: element.closest('section').querySelector('.js-obaia-history-slider-prev'),
        //     nextEl: element.closest('section').querySelector('.js-obaia-history-slider-next')
        // },
        // ? Initial slide is needed because the controller is inversed, so we start at the end of the slider
        initialSlide: element.querySelectorAll('.swiper-slide').length,
        controller: {
            inverse: true,
        },
    });

    const swiperThumbs = new Swiper(thumbsElement, {
        modules: [Navigation, Pagination, Mousewheel, Controller, FreeMode, Autoplay],
        slidesPerView: "auto",
        freeMode: true,
        spaceBetween: 20,
        mousewheel: {
            forceToAxis: true,
        },
        autoplay: {
            delay: 3000
        }
    });

    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel, Controller, Thumbs],
        slidesPerView: 1,
        // speed: 600,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            prevEl: element.closest('section').querySelector('.js-obaia-history-slider-prev'),
            nextEl: element.closest('section').querySelector('.js-obaia-history-slider-next')
        },
        thumbs: {
            swiper: swiperThumbs
        },
        controller: {
            inverse: true,
        },
    });

    swiperText.controller.control = swiper
    swiper.controller.control = swiperText
});

document.querySelectorAll('.js-obaia-zozietjedageruit-slider').forEach(element => {
    const swiper = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel],
        // loop: true,
        slidesPerView: "auto",
        spaceBetween: 0,
        autoHeight: false,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            prevEl: element.closest('section').querySelector('.js-obaia-zozietjedageruit-slider-prev'),
            nextEl: element.closest('section').querySelector('.js-obaia-zozietjedageruit-slider-next')
        },
    });
});

// document.querySelectorAll('.js-obaia-calendar-slider').forEach(element => {
//     const swiper = new Swiper(element, {
//         modules: [Navigation, Pagination, Mousewheel, FreeMode],
//         // loop: true,
//         // direction: "horizontal",
//         freeMode: true,
//         slidesPerView: "auto",
//         //  slidesPerView: 4,
//         //  grid: {
//         //     // fill: 'column',
//         //     rows: 3,
//         // },
//         spaceBetween: 0,
//         // autoHeight: true,
//         mousewheel: {
//             forceToAxis: true,
//         },
//         navigation: {
//             prevEl: element.closest('section').querySelector('.js-obaia-calendar-slider-prev'),
//             nextEl: element.closest('section').querySelector('.js-obaia-calendar-slider-next')
//         },
//     });
// });