/**
 * This file kicks off Webpack's tasks to minify and compile CSS and JS.
 */

const lightning = String.fromCodePoint(0x26A1)
const flame = String.fromCodePoint(0x1f525)

// CSS imports
import './assets/scss/main.scss'

// JS imports
// Vendors
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.start()

import AOS from 'aos'

AOS.init()

// Components
import './assets/js/_header'
import './assets/js/_validator-pristine'
import './assets/js/_micromodal'
import './assets/js/_swiper'
import './assets/js/_draggable'
import './assets/js/_count-up'
import './assets/js/_file-input-reader'
import './assets/js/_360-image-frames'

// END OF FILE
console.log(" Made with a keyboard by obaia " + flame)
// if (module.hot) {
//     module.hot.accept()
// }