import MicroModal from 'micromodal';

MicroModal.init({
    onClose: modal => {
        console.info(`${modal.id} is hidden`)
        modal.querySelectorAll('iframe').forEach(iframe => {
            iframe.setAttribute('src', iframe.getAttribute('src'));
        });
    },
});

// MicroModal.close('modal-XXX');
// MicroModal.show('modal-XXX');