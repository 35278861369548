document.querySelectorAll('.js-obaia-draggable').forEach(draggableElement => {
    draggableElement.style.cursor = 'grab'

    // ? this timer system is build to wait for the scrolling behavior: smooth to end.
    let timer
    draggableElement.addEventListener('scroll', () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            syncButtons()
        }, 50)
    }, { passive: true })

    const buttonPrev = draggableElement.closest('section').querySelector('.js-obaia-calendar-draggable-prev')
    const buttonNext = draggableElement.closest('section').querySelector('.js-obaia-calendar-draggable-next')
    const draggableLeftGradient = draggableElement.closest('section').querySelector('.js-obaia-draggable-gradient-left')
    const draggableRighGradient = draggableElement.closest('section').querySelector('.js-obaia-draggable-gradient-right')

    let tableWidth = 280

    const getMaxScrollLeft = function (e) {
        return (draggableElement.scrollWidth - draggableElement.clientWidth);
    }

    const syncButtons = function (e) {
        let maxScrollLeft = getMaxScrollLeft()
        if (draggableElement.scrollLeft <= 0) {
            buttonPrev.disabled = true
            draggableLeftGradient.classList.remove('!scale-x-100')
        } else {
            buttonPrev.disabled = false
            draggableLeftGradient.classList.add('!scale-x-100')
        }
        if (draggableElement.scrollLeft >= maxScrollLeft) {
            buttonNext.disabled = true
            draggableRighGradient.classList.add('!scale-x-0')
        } else {
            buttonNext.disabled = false
            draggableRighGradient.classList.remove('!scale-x-0')
        }
    }

    const getScrollingRange = function (direction) {
        let countPosition
        for (let index = 0; index < 8; index++) {
            countPosition = tableWidth * index

            // ? direction = right
            if ( direction == 'right' && countPosition > draggableElement.scrollLeft ) {
                break
            }

            // ? direction = left // this piece of shit code took me half a day
            if ( direction == 'left') {
                let scrollRight = draggableElement.scrollLeft - getMaxScrollLeft()
                 if ( countPosition >= ( getMaxScrollLeft() - Math.abs(scrollRight) ) ) {
                    countPosition = countPosition - tableWidth
                    break
                }
            }
        }
        return countPosition
    }

    const scrollLeft = function (e) {
        // TODO: make a algorithm that knows where you are to 'snap' tot the next section
        draggableElement.scroll({
            left: getScrollingRange('left'),
            top: 0,
            behavior: 'smooth'
        })
    }

    const scrollRight = function (e) {
        draggableElement.scroll({
            left: getScrollingRange('right'),
            top: 0,
            behavior: 'smooth'
        })
    }

    buttonPrev.addEventListener('click', scrollLeft, { passive: true })
    buttonNext.addEventListener('click', scrollRight, { passive: true })

    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const mouseDownHandler = function (e) {
        e.preventDefault()
        draggableElement.style.cursor = 'grabbing'
        draggableElement.style.userSelect = 'none'
        // draggableElement.style.scrollBehavior = 'auto'

        pos = {
            left: draggableElement.scrollLeft,
            top: draggableElement.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        }

        document.addEventListener('mousemove', mouseMoveHandler, { passive: true })
        document.addEventListener('mouseup', mouseUpHandler, { passive: true })
    }

    const mouseMoveHandler = function (e) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y
        // draggableElement.style.scrollBehavior = 'auto'

        // Scroll the element
        draggableElement.scrollTop = pos.top - dy
        draggableElement.scrollLeft = pos.left - dx

        syncButtons()
    }

    const mouseUpHandler = function () {
        draggableElement.style.cursor = 'grab'
        // draggableElement.style.scrollBehavior = 'smooth'
        draggableElement.style.removeProperty('user-select')

        document.removeEventListener('mousemove', mouseMoveHandler, { passive: true })
        document.removeEventListener('mouseup', mouseUpHandler, { passive: true })
    }

    // Attach the handler
    draggableElement.addEventListener('mousedown', mouseDownHandler)
})