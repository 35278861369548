const obaiaHeaderMenu = document.getElementById('js-obaia-header-menu');
const obaiaHamburgerClose = document.getElementById('js-obaia-close-responsive-menu');
const obaiaHamburgerOpen = document.getElementById('js-obaia-open-responsive-menu');
const obaiaHamburgerMenu = document.getElementById('js-obaia-responsive-menu');

const obaiaSearchButton = document.getElementById('js-obaia-search')
const obaiaSearchInput = document.getElementById('js-obaia-search-input')
const obaiaSearchClose = document.getElementById('js-obaia-search-close')

obaiaSearchButton.onclick = function () {
    obaiaSearchInput.classList.toggle('!translate-y-0')
    obaiaSearchInput.querySelector('input').focus()
}
obaiaSearchClose.onclick = function () {
    obaiaSearchInput.classList.toggle('!translate-y-0')
}
function toggleMenuState() {
    obaiaHamburgerMenu.classList.toggle('scale-x-100');
}

function checkMenuState() {
    let windowScrollTop = window.pageYOffset;
    if (windowScrollTop >= 80) {
        obaiaHeaderMenu.classList.add('is-scrolled');
    } else {
        obaiaHeaderMenu.classList.remove('is-scrolled');
    }
}

function setWindowHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setWindowHeight()
// window.addEventListener('load', (e) => {
obaiaHamburgerOpen.addEventListener('click', function () {
    toggleMenuState();
    // obaiaHamburgerMenu.classList.toggle('scale-100');
}, { passive: true });
obaiaHamburgerClose.addEventListener('click', function () {
    toggleMenuState();
    // obaiaHamburgerMenu.classList.toggle('scale-100');
}, { passive: true });


function debounce(func, wait, immediate) {
    var timeout
    return function executedFunction() {
        var context = this
        var args = arguments
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

window.addEventListener('load', checkMenuState, { passive: true })
// window.addEventListener('scroll', debounce(checkMenuState,100), { passive: true })
window.addEventListener('scroll', checkMenuState, { passive: true })
window.addEventListener('resize', setWindowHeight, { passive: true })
// });


