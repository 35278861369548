function preloadImage(url) {
    const img = new Image();
    img.src = url
    return img
}

function preloadImages(images) {
    for (var i = 0; i < images.length; i++) {
        images[i] = preloadImage(images[i])
    }
    return images
}

const framedImages = document.querySelectorAll('.js-obaia-rotate-frames')
for (let index = 0; index < framedImages.length; index++) {
    const element = framedImages[index]
    const imagesArray = JSON.parse(element.getAttribute('data-image-urls'))
    const centerImage = Math.floor(imagesArray.length / 2)
    const mouseTrackerWrapper = element.closest('.js-obaia-rotate-frames-tracker')

    // element.src = imagesArray[centerImage]

    // ? this transforms the array content into preloaded img dom object
    preloadImages(imagesArray)

    let imageFlag

    mouseTrackerWrapper.addEventListener('mouseleave', function (event) {
        element.src = imagesArray[centerImage].src
    })
    mouseTrackerWrapper.addEventListener('mousemove', function (event) {
        // let y = (event.clientY - mouseTrackerWrapper.offsetTop) * 100 / mouseTrackerWrapper.offsetHeight + "%";
        // let x = ( event.clientX - mouseTrackerWrapper.offsetLeft ) * 100 / mouseTrackerWrapper.offsetWidth
        // let x = event.clientX * 100 / mouseTrackerWrapper.offsetWidth
        let x = event.clientX * 100 / mouseTrackerWrapper.offsetWidth
        let selectImage = Math.floor( imagesArray.length / 100 * x )

        if (selectImage != imageFlag) {
            if (imagesArray[selectImage] != null) {
                // The == and != operators consider null equal to only null or undefined
                element.src = imagesArray[selectImage].src
                imageFlag = selectImage
                console.log("CHANGE THE IMAGE DUE TO VIRTUAL SECTION CHANGE");
            }
        }
    })
}