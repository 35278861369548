import { CountUp } from "countup.js";

// define an observer instance
let observer = new IntersectionObserver(onIntersection, {
    root: null,   // default is the viewport
    threshold: .5 // percentage of taregt's visible area. Triggers "onIntersection"
})

function log10(n) {
    return Math.round(100 * Math.log(n) / Math.log(10)) / 100
}

function indexesOf(string, regex) {
    var match,
        indexes = {}

    regex = new RegExp(regex)

    while (match = regex.exec(string)) {
        if (!indexes[match[0]]) indexes[match[0]] = []
        indexes[match[0]].push(match.index)
    }

    return indexes
}
// callback is called on intersection change
function onIntersection(entries, opts) {
    entries.forEach(entry => {
        entry.target.classList.toggle('visible', entry.isIntersecting)

        let countToNumber = entry.target.getAttribute('data-obaia-count-number').trim().replace(/\s/g, '')
        let countToPrefix = ''
        let countToSuffix = ''
        let decimalPlaces = 0
        
        // var countDecimals = function(value) {
        //     console.log(value);
        //     if (Math.floor(value) !== value)
        //         return value.toString().split(",")[1].length || 0;
        //     return 0;
        // }
        // if (countToNumber.includes(',')) {
        //     decimalPlaces = countDecimals(countToNumber) || 0;
        // }

        if (! /^\d+$/.test(countToNumber)) {
            // let numberOnly = countToNumber.replace(/[^0-9]/g, '')
            let numberOnly = countToNumber.replace(/[^0-9]/g, '')
            // let numberOnly = countToNumber.replace(/^\d*\,?\d*$/g, '')
            let countToSymbol = countToNumber.replace(numberOnly, '').trim()
            if (countToNumber.indexOf(countToSymbol) <= 0) {
                countToPrefix = countToSymbol
            }
            if (countToNumber.indexOf(countToSymbol) > 0) {
                countToSuffix = countToSymbol
            }
            countToNumber = numberOnly
        }
        
        // const countUp = new CountUp(entry.target, countToNumber, { startVal: 0, duration: log10(countToNumber), prefix: countToPrefix, suffix: countToSuffix });
        const countUp = new CountUp(entry.target, countToNumber, { startVal: 0, duration: log10(countToNumber), prefix: countToPrefix, suffix: countToSuffix, decimalPlaces: decimalPlaces, decimal: ',' });

        if (!countUp.error) {
            countUp.start()
            // To stop observing:
            // countUp.start(() => observer.unobserve(entry.target))
        } else {
            console.error(countUp.error)
        }
    })
}

document.querySelectorAll('.js-obaia-counter').forEach(element => {
    observer.observe(element)
});